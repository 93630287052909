import React from "react";
import styled from "styled-components";
import logo from "../resources/evry-logo.svg";

const IconFrame = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    height: 80px;
    width: 80px;

    margin-top: 20px;
    overflow: hidden;
    background-color: #ffffff;
`;

const AuthenticationIcon = () => (
    <IconFrame>
        <div style={{ marginTop: 4 }} className="icon">
            <img src={logo} alt="logo" style={{ height: 40 }} />
        </div>
    </IconFrame>
);

export default AuthenticationIcon;
