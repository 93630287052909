import React, { Component } from "react";
import PropTypes from "prop-types";

const PageStatus = {
  NONE: "",
  LOADING: "loading",
  RENDERING: "rendering",
  RENDERED: "rendered"
};

class Page extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: PageStatus.NONE
    };

    this.setCanvasRef = this.setCanvasRef.bind(this);
    this.updatePage = this.updatePage.bind(this);
  }

  componentDidMount() {
    this.updatePage();
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { pdf, scale } = this.props;
    const { status } = this.state;

    return pdf !== nextProps.pdf || status !== nextState.status || scale !== nextProps.scale;
  }

  componentDidUpdate(prevProps) {
    const { pdf, scale } = this.props;
    if (prevProps.pdf !== pdf || prevProps.scale !== scale) {
      this.updatePage();
    }
  }

  setCanvasRef(canvas) {
    this.canvas = canvas;
  }

  updatePage() {
    const { pdf, index } = this.props;
    const { status } = this.state;

    if (status === PageStatus.LOADING || status === PageStatus.RENDERING) {
      return;
    }

    this.setState({ status: PageStatus.LOADING });
    pdf.getPage(index).then((page) => {
      this.setState({ status: PageStatus.RENDERING });
      this.renderPage(page);
    });
  }

  renderPage(page) {
    const { scale } = this.props;
    const { canvas } = this;

    const viewport = page.getViewport({ scale });
    const context = canvas.getContext("2d");
    const { width, height } = viewport;

    canvas.width = width;
    canvas.height = height;

    page.render({
      canvasContext: context,
      viewport
    });

    this.setState({ status: PageStatus.RENDERED });
  }

  render() {
    return (
      <canvas ref={this.setCanvasRef} />
    );
  }
}

Page.propTypes = {
  pdf: PropTypes.shape({}).isRequired,
  scale: PropTypes.number,
  index: PropTypes.number,
};

Page.defaultProps = {
  scale: 1,
  index: 0
};

export default Page;
