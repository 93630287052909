import React, { Component } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Icons } from "@flow/icons";
import { TransparentButton } from "@flow/buttons";
import { Colors } from "@flow/style";

import debounce from "../../helpers/debonce";

const DEFAULT_SCALE = 1;
const MAX_SCALE = 5;
const MIN_SCALE = 1;
const SCALE_DELTA = 1.1;

const Toolbar = styled.div`
    position: absolute;
    background-color: ${Colors.SeaLighter};
    z-index: 2;
`;

class ZoomToolbar extends Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.zoomIn = this.zoomIn.bind(this);
        this.zoomOut = this.zoomOut.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
    }

    componentDidMount() {
        document.addEventListener("keydown", this.onKeyDown, false);
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.onKeyDown, false);
    }

    onKeyDown(e) {
        if (e.keyCode === 107 || e.keyCode === 187) {
            this.zoomIn();
        }
        if (e.keyCode === 109 || e.keyCode === 189) {
            this.zoomOut();
        }
    }

    zoomIn() {
        const { scale, onZoom } = this.props;

        let newScale = (scale * SCALE_DELTA).toFixed(2);
        newScale = Math.ceil(newScale * 10) / 10;
        newScale = Math.min(MAX_SCALE, newScale);

        onZoom(newScale);
    }

    zoomOut() {
        const { scale, onZoom } = this.props;

        let newScale = (scale / SCALE_DELTA).toFixed(2);
        newScale = Math.floor(newScale * 10) / 10;
        newScale = Math.max(MIN_SCALE, newScale);

        onZoom(newScale);
    }

    render() {
        const { scale } = this.props;

        const scaleIsMin = scale === MIN_SCALE;
        const scaleIsMax = scale === MAX_SCALE;

        return (
            <Toolbar>
                <TransparentButton
                    title="Zoom in (Ctrl +)"
                    type="button"
                    disabled={scaleIsMax}
                    onClick={debounce(this.zoomIn, 100)}
                >
                    <Icons.Plus
                        color={scaleIsMax ? Colors.Grey2 : Colors.Sea}
                    />
                </TransparentButton>
                <TransparentButton
                    title="Zoom out (Ctrl -)"
                    type="button"
                    disabled={scaleIsMin}
                    onClick={debounce(this.zoomOut, 100)}
                >
                    <Icons.Minus
                        color={scaleIsMin ? Colors.Grey2 : Colors.Sea}
                    />
                </TransparentButton>
            </Toolbar>
        );
    }
}

ZoomToolbar.propTypes = {
    scale: PropTypes.number,
    onZoom: PropTypes.func
};

ZoomToolbar.defaultProps = {
    scale: DEFAULT_SCALE,
    onZoom: () => {}
};

export default ZoomToolbar;
