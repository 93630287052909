import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { withTranslation } from "react-i18next";

import ZoomToolbar from "./ZoomToolbar";

const DEFAULT_SCALE = 1;

const StyledImage = styled.img`
  width: 100%;
  display: block;
  transform-origin: top left;
  transform: scale(${props => props.scale});
`;

class ImageViewer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scale: DEFAULT_SCALE
    };

    this.resetScale = this.resetScale.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { file } = this.props;
    if (prevProps.file !== file) {
      this.resetScale();
    }
  }

  resetScale() {
    this.setState({ scale: DEFAULT_SCALE });
  }

  render() {
    const { file, type, t } = this.props;
    const { scale } = this.state;
    const imageUrl = window.URL.createObjectURL(new Blob([file], { type }));

    return (
      <div>
        <ZoomToolbar
          scale={scale}
          onZoom={(newScale) => { this.setState({ scale: newScale }); }}
        />
        <StyledImage alt={t("Uploaded document")} src={imageUrl} scale={scale} />
      </div>
    );
  }
}

ImageViewer.propTypes = {
  type: PropTypes.string.isRequired,
  file: PropTypes.shape({}).isRequired,
  t: PropTypes.func.isRequired
};

export default withTranslation("inbox")(ImageViewer);
