import React from "react";
import styled from "styled-components";

const MainLogo = () => (
    <MainLogoWrapper>
        <div>
            <svg
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                x="0"
                y="0"
                viewBox="0 0 286 149"
                height="45px"
            >
                <path
                    fill="#FFFFFF"
                    d="M80.564 31.919c3.33-.569 7.79-1.402 9.013 7.123.369 2.57.378 32.325.378 32.325s3.205-3.058 13.042-18.534c7.455-11.73 15.694-26.863 18.789-32.169 1.815-3.113 5.157-6.339 10.942-2.004 6.445 4.831 6.592 8.326 3.758 13.28-2.912 5.085-10.72 18.055-21.773 33.677-10.036 14.187-14.834 19.813-19.059 24.499-4.226 4.685-9.849 10.087-15.72 9.254-7.792-1.104-7.192-14.556-7.502-18.406-.309-3.85-1.839-34.409-.789-41.543.408-2.769.297-6.031 8.921-7.502zm105.412.793c3.594 1.953-.531 10.393-7.458 15.514-4.461 3.299-12.083 8.254-19.931 8.254 3.83-6.781 8.633-12.284 12.119-15.772 5.602-5.6 12.136-9.701 15.27-7.996zm97.354-1.86c3.468-4.531 4.16-7.244-1.587-12.888-5.156-5.066-8.614-2.081-11.136.546-.417.508-12.109 12.162-24.415 26.363-9.577 11.055-17.866 21.335-17.866 21.335s-.295-6.929.736-15.625c.587-4.952 2.211-14.598 2.691-17.649 2.431-15.476-7.071-16.095-10.609-16.314-3.723-.232-5.491 1.758-6.596 6.475-1.014 4.324-6.557 28.079-4.347 52.545.399 4.414 1.252 8.949 1.334 9.246l-2.236 3.121c-20.839-5.894-35.11-20.218-35.11-20.218s6.834-2 14.351-6.595c5.968-3.648 17.462-12.674 16.246-28.593-1.032-13.515-15.141-19.676-20.335-18.571-4.728 1.005-13.005 6.78-20.816 13.854-.528-3.695-.618-7.765-4.383-8.216-2.763-.332-5.968.995-8.289 2.211-2.061 1.08-4.31 2.654-5.968 5.748l-8.769 20.223c-.283.721-.853.87-1.311.975-4.693 1.089-6.205 4.505-6.539 5.456 0 0-1.962 4.219 1.502 8.034-1.737 4.673-6.349 15.577-6.349 15.577-5.721 13.371-5.353 18.014-3.307 20.858 1.039 1.446 4.68 3.534 9.08 4.266 3.316.553 5.851-1.44 8.62-5.859 4.089-6.522 12.305-23.729 12.305-23.729s16.33 17.907 49.107 29.686c0 0-17.332 23.969-19.835 37.219 0 0-1.29 8.687 10.39 8.664 2.933-.005 7.357-.71 10.554-2.679 1.398-.86 2.3-2.052 2.653-3.42.162-.625.027-1.366-.245-1.858-.315-.568-.827-.884-1.204-1.259-.601-.601-.513-1.376-.187-2.269 6.428-18.76 32.555-50.648 38.102-57.561 14.699-18.312 41.845-46.82 43.768-49.099z"
                />
                <path
                    fill="#FFFFFF"
                    d="M5.277 85.669c1.221 3.094 1.344 5.171.855 8.406a172.439 172.439 0 0 1-1.29 7.345c-.414 2.112-1.395 6.687.04 8.427 1.392 1.685 4.172 1.591 7.413 1.19 3.594-.446 21.574-4.161 40.482-12.115 2.566-1.128 5.188-2.489 7.414-4.194 5.136-3.94 5.963-12.206 5.963-12.206.133-1.579.112-3.842-1.955-5.014-1.504-.853-3.585-.319-6.699.529-5.155 1.404-17.904 6.301-22.325 7.627-1.793.539-5.324 1.383-6.687-.331-2.265-2.848.977-11.774 2.92-16.748 1.943-4.975 4.99-8.968 14.362-11.667 3.44-.991 9.076-2.779 11.374-3.561 2.511-.858 5.826-2.515 7.235-8.2 1.606-6.486-3.314-7.481-7.804-8.726-6.425-1.784-10.46-2.006-7.641-8.252 1.152-2.553 2.229-3.685 4.735-4.347 2.553-.677 24.178-4.106 32.787-5.676 4.823-.877 6.459-3.478 8.472-7.667 4.355-9.059-1.101-9.652-5.51-9.244-9.961.921-25.95 3.831-32.802 4.826-3.651.531-1.218-5.705-5.485-6.005-8.388-.589-15.517 2.948-18.365 5.159-2.694 2.09-3.977 8.545-3.977 8.545l-8.31 28.775c-.717 3.321-1.739 6.595-4.089 8.004C10.676 53.984-.975 54.22.066 66.833c-.001-.002.227 6.195 5.211 18.836z"
                />
            </svg>
        </div>
    </MainLogoWrapper>
);

export default MainLogo;

export const MainLogoWrapper = styled.div`
    padding-left: 32px;
    padding-top: 7.5px;
    padding-bottom: 7.5px;

    .title {
        font-family: Montserrat Alternates;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        font-size: 24px;
        text-align: right;

        color: #2b2b2b;
        padding-right: 5px;
    }

    .cloud {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        font-size: 24px;
        text-align: right;

        color: #d44235;
    }
`;
