import React from "react";
import {withTranslation} from "react-i18next";

export class CaseDetails extends React.Component {
    render() {
        return (
            <div>
                <pre>{JSON.stringify(this.props.data, undefined, 2)}</pre>
            </div>
        );
    }
}

export const CaseDetailsTranslated = withTranslation("inbox")(CaseDetails);
