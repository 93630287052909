import React, { Component } from "react";
import PropTypes from "prop-types";
import * as PdfJs from "pdfjs-dist";
import { CenteredSpinner } from "@flow/icons";

import ZoomToolbar from "./ZoomToolbar";
import Page from "./PdfPage";

PdfJs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${PdfJs.version}/pdf.worker.js`;

const DEFAULT_SCALE = 1;

class PdfViewer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pdf: null,
      scale: DEFAULT_SCALE,
    };

    this.getPdfDocument = this.getPdfDocument.bind(this);
  }

  componentDidMount() {
    this.getPdfDocument();
  }

  componentDidUpdate(prevProps) {
    const { fileId } = this.props;
    if (prevProps.fileId !== fileId) {
      this.getPdfDocument();
    }
  }

  getPdfDocument() {
    const { file } = this.props;
    PdfJs.getDocument(file).promise.then((pdf) => {
      this.setState({ pdf, scale: DEFAULT_SCALE });
    });
  }

  render() {
    const { pdf, scale } = this.state;
    // eslint-disable-next-line no-underscore-dangle
    const numPages = pdf ? pdf._pdfInfo.numPages : 0;

    if (!pdf) {
      return <CenteredSpinner />;
    }

    return (
      <div>
        <ZoomToolbar
          scale={scale}
          onZoom={(newScale) => { this.setState({ scale: newScale }); }}
        />
        <div>
          { Array.from(new Array(numPages)).map((page, i) => (
            <Page
              scale={scale}
              pdf={pdf}
              index={i + 1}
              key={`pdf-page-${i}`}
            />
          ))}
        </div>
      </div>
    );
  }
}

PdfViewer.propTypes = {
  fileId: PropTypes.string,
  file: PropTypes.shape({}).isRequired
};

PdfViewer.defaultProps = {
  fileId: null
};

export default PdfViewer;
