import styled from "styled-components";

export default styled.div`
    .mainHeader {
        background-color: #043852;
    }

    .mainMenu {
        background-color: #043852;
    }

    .mainMenuIcon.active {
        color: white;
        border-left: 3px solid white;
    }

    .activeElement {
        border-left: 2px solid #032b3f;
    }
`;
